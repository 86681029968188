import request from "@/utils/request";

export function getRosterGameData(params) {
  return request({
    url: "/roster_game_data/",
    method: "get",
    params
  });
}

export function getRosterSeasonData(params) {
  return request({
    url: "/roster_season_data/",
    method: "get",
    params
  });
}
