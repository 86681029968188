<template lang="pug">
.statistics
  .top-dropdown
    v-container
      v-row(justify="center")
        v-col.select_label
          span.text-body-2 選擇賽季：
        v-col.clear(cols="auto", style="padding: 0")
          select.selector(name="seasonInquiry", v-model="seasonInquiryType")
            option(
              :value="index",
              :key="index",
              v-for="(item, index) in leagueData.info.seasons"
            ) {{ item.name }}
  v-container
    v-row.mt-10
      v-col(cols="4")
        h5.normal_title_h5 隊內排名
    v-row.mt-8
      v-col.position-relative(
        cols="12",
        md="6",
        v-for="(rankingList, index) in playersRankingLists",
        :key="index"
      )
        players-ranking-card(
          :playersRankingList="rankingList",
          @playerClick="playerClick"
        )
  v-container.ranking_league_bar
    v-row.mt-10
      v-col(cols="4")
        h5.normal_title_h5 聯盟排名
    v-row(align="center")
      v-col(cols="12", md="5")
        high-chart-advanced-polar(
          v-if="polarObject.averagePercentList.length > 0",
          :personalList="polarObject.personalList",
          :personalPercentList="polarObject.personalPercentList",
          :averageList="polarObject.averageList",
          :averagePercentList="polarObject.averagePercentList",
          :personalSeriesTitle="'隊伍'"
        )
      v-col.offset-0.offset-md-1(cols="12", md="6")
        .bar.d-flex.align-center.mt-5(
          v-for="(ranking, rankingIndex) in barRankingList",
          :key="rankingIndex"
        )
          .title.text_light_gray.text-body-1.font-weight-bold.d-inline-block.ml-4 {{ ranking.title }}
          .ranking.text-center.ranking_mark(
            :class="[ranking.ranking <= 3 ? 'text_secondary' : 'lightGray']"
          ) {{ getRankText(ranking.ranking) }}
          percentage-bar(
            :value="ranking.value",
            :deno="ranking.deno",
            :percent="ranking.percent"
          )
  v-container.table_container.mt-15
    v-row.justify-space-between
      v-col(cols="auto")
        h5.normal_title_h5 進階數據
    v-row.table_row
      v-col.table_col.table.position-relative.mt-5(cols="12")
        table
          thead.top_head
            tr
              sticky-column.bdr2.bdl(
                colspan="1",
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(colspan="5")
              th.bdr(colspan="3") 盯人進攻
              th.bdr(colspan="3") 區域進攻
              th.bdr(colspan="3") 全場壓迫進攻
              th.bdr(colspan="4") 
              th.bdr(colspan="3") 盯人防守
              th.bdr(colspan="3") 區域防守
              th.bdr(colspan="3") 全場壓迫防守
          thead
            tr.bottom_head
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(
                :class="{ bdr: title.divider }",
                v-for="(title, index) in teamHeadTitleList",
                :key="index"
              ) {{ title.title }}

          tbody
            tr(v-for="(game, index) in teamSeasonAllData", :key="index")
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0",
                title="整季"
              )
              td.bdr {{ (game.advancement.avg_pts - game.advancement.avg_opp_pts).toFixed(1) }}
              td {{ game.advancement.avg_poss.toFixed(1) }}
              td {{ (game.advancement.to_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ game.advancement.ppp.toFixed(1) }}
              td.bdr {{ (game.advancement.off_reb_rate * 100).toFixed(1) + '%' }}
              td {{ game.vs_defense.vs_man.avg_pts.toFixed(1) }}
              td {{ game.vs_defense.vs_man.avg_play.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_man.ppp.toFixed(1) }}
              td {{ game.vs_defense.vs_zone.avg_pts.toFixed(1) }}
              td {{ game.vs_defense.vs_zone.avg_play.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_zone.ppp.toFixed(1) }}
              td {{ game.vs_defense.vs_full_court.avg_pts.toFixed(1) }}
              td {{ game.vs_defense.vs_full_court.avg_play.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_full_court.ppp.toFixed(1) }}
              td {{ game.advancement.avg_opp_poss.toFixed(1) }}
              td {{ game.advancement.opp_ppp.toFixed(1) }}
              td {{ (game.advancement.opp_to_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ (game.advancement.def_reb_rate * 100).toFixed(1) + '%' }}
              td {{ game.opp_vs_defense.opp_vs_man.avg_pts.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_man.avg_play.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_man.ppp.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_zone.avg_pts.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_zone.avg_play.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_zone.ppp.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_full_court.avg_pts.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_full_court.avg_play.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_full_court.ppp.toFixed(1) }}
    v-row.table_row
      v-col.table_col.table.position-relative.mt-15(cols="12")
        table
          thead.top_head
            tr
              sticky-column.bdr2.bdl(
                colspan="1",
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(colspan="5")
              th.bdr(colspan="3") 盯人進攻
              th.bdr(colspan="3") 區域進攻
              th.bdr(colspan="3") 全場壓迫進攻
              th.bdr(colspan="4") 
              th.bdr(colspan="3") 盯人防守
              th.bdr(colspan="3") 區域防守
              th.bdr(colspan="3") 全場壓迫防守
          thead
            tr.bottom_head
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(
                :class="{ bdr: title.divider }",
                v-for="(title, index) in teamHeadTitleList",
                :key="index"
              ) {{ title.title }}

          tbody
            tr(v-for="(game, index) in teamSeasonDateData", :key="index")
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="game.info.split_info.name"
              )
              td.bdr {{ (game.advancement.avg_pts - game.advancement.avg_opp_pts).toFixed(1) }}
              td {{ game.advancement.avg_poss.toFixed(1) }}
              td {{ (game.advancement.to_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ game.advancement.ppp.toFixed(1) }}
              td.bdr {{ (game.advancement.off_reb_rate * 100).toFixed(1) + '%' }}
              td {{ game.vs_defense.vs_man.avg_pts.toFixed(1) }}
              td {{ game.vs_defense.vs_man.avg_play.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_man.ppp.toFixed(1) }}
              td {{ game.vs_defense.vs_zone.avg_pts.toFixed(1) }}
              td {{ game.vs_defense.vs_zone.avg_play.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_zone.ppp.toFixed(1) }}
              td {{ game.vs_defense.vs_full_court.avg_pts.toFixed(1) }}
              td {{ game.vs_defense.vs_full_court.avg_play.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_full_court.ppp.toFixed(1) }}
              td {{ game.advancement.avg_opp_poss.toFixed(1) }}
              td {{ game.advancement.opp_ppp.toFixed(1) }}
              td {{ (game.advancement.opp_to_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ (game.advancement.def_reb_rate * 100).toFixed(1) + '%' }}
              td {{ game.opp_vs_defense.opp_vs_man.avg_pts.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_man.avg_play.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_man.ppp.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_zone.avg_pts.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_zone.avg_play.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_zone.ppp.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_full_court.avg_pts.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_full_court.avg_play.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_full_court.ppp.toFixed(1) }}
    v-row.table_row
      v-col.table_col.table.position-relative.mt-15(cols="12")
        table
          thead.top_head
            tr
              sticky-column.bdr2.bdl(
                colspan="1",
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(colspan="5")
              th.bdr(colspan="3") 盯人進攻
              th.bdr(colspan="3") 區域進攻
              th.bdr(colspan="3") 全場壓迫進攻
              th.bdr(colspan="4") 
              th.bdr(colspan="3") 盯人防守
              th.bdr(colspan="3") 區域防守
              th.bdr(colspan="3") 全場壓迫防守
          thead
            tr.bottom_head
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(
                :class="{ bdr: title.divider }",
                v-for="(title, index) in teamHeadTitleList",
                :key="index"
              ) {{ title.title }}

          tbody
            tr(
              v-for="(quarter, index) in teamSeasonQuarterCount",
              :key="index",
              v-if="quarter != 1"
            )
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="getQuarterText(quarter - 1)"
              )
              td.bdr {{ (teamSeasonQuarterData[0].advancement[quarter - 1].avg_pts - teamSeasonQuarterData[0].advancement[quarter - 1].avg_opp_pts).toFixed(1) }}
              td {{ teamSeasonQuarterData[0].advancement[quarter - 1].avg_poss.toFixed(1) }}
              td {{ (teamSeasonQuarterData[0].advancement[quarter - 1].to_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ teamSeasonQuarterData[0].advancement[quarter - 1].ppp.toFixed(1) }}
              td.bdr {{ (teamSeasonQuarterData[0].advancement[quarter - 1].off_reb_rate * 100).toFixed(1) + '%' }}
              td {{ teamSeasonQuarterData[0].vs_defense.vs_man[quarter - 1].avg_pts.toFixed(1) }}
              td {{ teamSeasonQuarterData[0].vs_defense.vs_man[quarter - 1].avg_play.toFixed(1) }}
              td.bdr {{ teamSeasonQuarterData[0].vs_defense.vs_man[quarter - 1].ppp.toFixed(1) }}
              td {{ teamSeasonQuarterData[0].vs_defense.vs_zone[quarter - 1].avg_pts.toFixed(1) }}
              td {{ teamSeasonQuarterData[0].vs_defense.vs_zone[quarter - 1].avg_play.toFixed(1) }}
              td.bdr {{ teamSeasonQuarterData[0].vs_defense.vs_zone[quarter - 1].ppp.toFixed(1) }}
              td {{ teamSeasonQuarterData[0].vs_defense.vs_full_court[quarter - 1].avg_pts.toFixed(1) }}
              td {{ teamSeasonQuarterData[0].vs_defense.vs_full_court[quarter - 1].avg_play.toFixed(1) }}
              td.bdr {{ teamSeasonQuarterData[0].vs_defense.vs_full_court[quarter - 1].ppp.toFixed(1) }}
              td {{ teamSeasonQuarterData[0].advancement[quarter - 1].avg_opp_poss.toFixed(1) }}
              td {{ teamSeasonQuarterData[0].advancement[quarter - 1].opp_ppp.toFixed(1) }}
              td {{ (teamSeasonQuarterData[0].advancement[quarter - 1].opp_to_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ (teamSeasonQuarterData[0].advancement[quarter - 1].def_reb_rate * 100).toFixed(1) + '%' }}
              td {{ teamSeasonQuarterData[0].opp_vs_defense.opp_vs_man[quarter - 1].avg_pts.toFixed(1) }}
              td {{ teamSeasonQuarterData[0].opp_vs_defense.opp_vs_man[quarter - 1].avg_play.toFixed(1) }}
              td.bdr {{ teamSeasonQuarterData[0].opp_vs_defense.opp_vs_man[quarter - 1].ppp.toFixed(1) }}
              td {{ teamSeasonQuarterData[0].opp_vs_defense.opp_vs_zone[quarter - 1].avg_pts.toFixed(1) }}
              td {{ teamSeasonQuarterData[0].opp_vs_defense.opp_vs_zone[quarter - 1].avg_play.toFixed(1) }}
              td.bdr {{ teamSeasonQuarterData[0].opp_vs_defense.opp_vs_zone[quarter - 1].ppp.toFixed(1) }}
              td {{ teamSeasonQuarterData[0].opp_vs_defense.opp_vs_full_court[quarter - 1].avg_pts.toFixed(1) }}
              td {{ teamSeasonQuarterData[0].opp_vs_defense.opp_vs_full_court[quarter - 1].avg_play.toFixed(1) }}
              td.bdr {{ teamSeasonQuarterData[0].opp_vs_defense.opp_vs_full_court[quarter - 1].ppp.toFixed(1) }}
    v-row.table_row
      v-col.table_col.table.position-relative.mt-15(cols="12")
        table
          thead.top_head
            tr
              sticky-column.bdr2.bdl(
                colspan="1",
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(colspan="5")
              th.bdr(colspan="3") 盯人進攻
              th.bdr(colspan="3") 區域進攻
              th.bdr(colspan="3") 全場壓迫進攻
              th.bdr(colspan="4") 
              th.bdr(colspan="3") 盯人防守
              th.bdr(colspan="3") 區域防守
              th.bdr(colspan="3") 全場壓迫防守
          thead
            tr.bottom_head
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(
                :class="{ bdr: title.divider }",
                v-for="(title, index) in teamHeadTitleList",
                :key="index"
              ) {{ title.title }}

          tbody
            tr(v-for="(game, index) in teamSeasonWinLoseData", :key="index")
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="game.info.split == 'WIN' ? '勝' : '負'"
              )
              td.bdr {{ (game.advancement.avg_pts - game.advancement.avg_opp_pts).toFixed(1) }}
              td {{ game.advancement.avg_poss.toFixed(1) }}
              td {{ (game.advancement.to_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ game.advancement.ppp.toFixed(1) }}
              td.bdr {{ (game.advancement.off_reb_rate * 100).toFixed(1) + '%' }}
              td {{ game.vs_defense.vs_man.avg_pts.toFixed(1) }}
              td {{ game.vs_defense.vs_man.avg_play.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_man.ppp.toFixed(1) }}
              td {{ game.vs_defense.vs_zone.avg_pts.toFixed(1) }}
              td {{ game.vs_defense.vs_zone.avg_play.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_zone.ppp.toFixed(1) }}
              td {{ game.vs_defense.vs_full_court.avg_pts.toFixed(1) }}
              td {{ game.vs_defense.vs_full_court.avg_play.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_full_court.ppp.toFixed(1) }}
              td {{ game.advancement.avg_opp_poss.toFixed(1) }}
              td {{ game.advancement.opp_ppp.toFixed(1) }}
              td {{ (game.advancement.opp_to_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ (game.advancement.def_reb_rate * 100).toFixed(1) + '%' }}
              td {{ game.opp_vs_defense.opp_vs_man.avg_pts.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_man.avg_play.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_man.ppp.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_zone.avg_pts.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_zone.avg_play.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_zone.ppp.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_full_court.avg_pts.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_full_court.avg_play.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_full_court.ppp.toFixed(1) }}
    v-row.table_row
      v-col.table_col.table.position-relative.mt-15(cols="12")
        table
          thead.top_head
            tr
              sticky-column.bdr2.bdl(
                colspan="1",
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(colspan="5")
              th.bdr(colspan="3") 盯人進攻
              th.bdr(colspan="3") 區域進攻
              th.bdr(colspan="3") 全場壓迫進攻
              th.bdr(colspan="4") 
              th.bdr(colspan="3") 盯人防守
              th.bdr(colspan="3") 區域防守
              th.bdr(colspan="3") 全場壓迫防守
          thead
            tr.bottom_head
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0"
              )
              th(
                :class="{ bdr: title.divider }",
                v-for="(title, index) in teamHeadTitleList",
                :key="index"
              ) {{ title.title }}

          tbody
            tr(v-for="(game, index) in teamSeasonVSTeamData", :key="index")
              sticky-column.bdr2.bdl(
                style="width: 100px; min-width: 100px",
                :offset="0",
                :title="'vs. ' + game.info.split_info.name"
              )
              td.bdr {{ (game.advancement.avg_pts - game.advancement.avg_opp_pts).toFixed(1) }}
              td {{ game.advancement.avg_poss.toFixed(1) }}
              td {{ (game.advancement.to_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ game.advancement.ppp.toFixed(1) }}
              td.bdr {{ (game.advancement.off_reb_rate * 100).toFixed(1) + '%' }}
              td {{ game.vs_defense.vs_man.avg_pts.toFixed(1) }}
              td {{ game.vs_defense.vs_man.avg_play.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_man.ppp.toFixed(1) }}
              td {{ game.vs_defense.vs_zone.avg_pts.toFixed(1) }}
              td {{ game.vs_defense.vs_zone.avg_play.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_zone.ppp.toFixed(1) }}
              td {{ game.vs_defense.vs_full_court.avg_pts.toFixed(1) }}
              td {{ game.vs_defense.vs_full_court.avg_play.toFixed(1) }}
              td.bdr {{ game.vs_defense.vs_full_court.ppp.toFixed(1) }}
              td {{ game.advancement.avg_opp_poss.toFixed(1) }}
              td {{ game.advancement.opp_ppp.toFixed(1) }}
              td {{ (game.advancement.opp_to_rate * 100).toFixed(1) + '%' }}
              td.bdr {{ (game.advancement.def_reb_rate * 100).toFixed(1) + '%' }}
              td {{ game.opp_vs_defense.opp_vs_man.avg_pts.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_man.avg_play.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_man.ppp.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_zone.avg_pts.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_zone.avg_play.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_zone.ppp.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_full_court.avg_pts.toFixed(1) }}
              td {{ game.opp_vs_defense.opp_vs_full_court.avg_play.toFixed(1) }}
              td.bdr {{ game.opp_vs_defense.opp_vs_full_court.ppp.toFixed(1) }}
  v-container.tab_container
    v-tabs(
      show-arrows,
      background-color="transparent",
      ripple="false",
      v-model="tab"
    )
      v-tabs-slider(color="primary")

      v-tab(:ripple="false", v-for="(item, index) in tabList", :key="index") {{ item }}
  .offensive_type
    v-container
      v-row(justify="end")
        v-col(cols="12", sm="4", md="3", lg="2")
          select.selector(name="typeInquiry", v-model="typeInquiryType")
            option(
              :value="index",
              v-for="(item, index) in typeInquiryList",
              :key="index"
            ) {{ item }}
        v-col(
          cols="12",
          sm="4",
          md="3",
          lg="2",
          v-if="tab == 3 && typeInquiryType != 0"
        )
          select.selector(name="dependInquiry", v-model="dependInquiryType")
            option(
              :value="index",
              v-for="(item, index) in dependInquiryList",
              :key="index"
            ) {{ item }}
    v-container.table_container(v-if="tab == 0")
      v-row.table_row
        v-col.table_col.table.position-relative(cols="12")
          table
            thead.top_head
              tr
                sticky-column.bdr2.bdl(
                  colspan="1",
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th.bdr(colspan="3") 快攻
                th.bdr(colspan="3") 定點
                th.bdr(colspan="3") 二波
                th.bdr(colspan="3") 無球
                th.bdr(colspan="3") 單打
                th.bdr(colspan="3") 切傳
                th.bdr(colspan="3") 擋拆
            thead
              tr.bottom_head
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th(
                  :class="{ bdr: title.divider }",
                  v-for="(title, index) in teamSeasonPlayerPlayTypeHeadTitleList",
                  :key="index"
                ) {{ title.title }}

            tbody
              tr(
                v-for="(game, index) in teamSeasonPlayerPlayTypeData",
                :key="index"
              )
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0",
                  :title="getTitle(game)"
                )
                td {{ (game.player_play_type.transition.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.transition.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.transition.to_rate * 100).toFixed(1) + '%' }}
                td {{ (game.player_play_type.spot_up.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.spot_up.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.spot_up.to_rate * 100).toFixed(1) + '%' }}
                td {{ (game.player_play_type.second_chance.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.second_chance.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.second_chance.to_rate * 100).toFixed(1) + '%' }}
                td {{ (game.player_play_type.off_ball.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.off_ball.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.off_ball.to_rate * 100).toFixed(1) + '%' }}
                td {{ (game.player_play_type.iso.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.iso.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.iso.to_rate * 100).toFixed(1) + '%' }}
                td {{ (game.player_play_type.dnk.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.dnk.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.dnk.to_rate * 100).toFixed(1) + '%' }}
                td {{ (game.player_play_type.pnr.rate * 100).toFixed(1) + '%' }}
                td {{ game.player_play_type.pnr.ppp.toFixed(1) }}
                td.bdr {{ (game.player_play_type.pnr.to_rate * 100).toFixed(1) + '%' }}
      v-row
        v-col.mt-15(
          cols="12",
          v-for="(game, index) in teamSeasonPlayerPlayTypeData",
          :key="index"
        )
          .chart_title.text-center {{ getTitle(game) }}
          high-chart-combo-percent-bar(
            :barDataList="getTeamSeasonPlayerPlayTypeDataList(game)",
            :categories="teamSeasonPlayerPlayTypeCategoryList"
          )
    v-container.table_container(v-if="tab == 1")
      v-row.table_row
        v-col.table_col.table.position-relative(cols="12")
          table
            thead.top_head
              tr
                sticky-column.bdr2.bdl(
                  colspan="1",
                  style="width: 120px; min-width: 120px",
                  :offset="0"
                )
                th.bdr(colspan="2") 禁區出手
                th.bdr(colspan="2") 外線空檔
                th.bdr(colspan="2") 外線干擾
                th.bdr(colspan="2") 失誤
                th.bdr(colspan="2") 上罰球線
            thead
              tr.bottom_head
                sticky-column.bdr2.bdl(
                  style="width: 120px; min-width: 120px",
                  :offset="0"
                )
                th(
                  :class="{ bdr: title.divider }",
                  v-for="(title, index) in teamSeasonShotStateHeadTitleList",
                  :key="index"
                ) {{ title.title }}

            tbody
              tr(
                v-for="(game, index) in teamSeasonShotStateData",
                :key="index"
              )
                sticky-column.bdr2.bdl(
                  style="width: 120px; min-width: 120px",
                  :offset="0",
                  :title="getTitle(game)"
                )
                td {{ (game.shot_state.shot_state_paint.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_state.shot_state_paint.ppp.toFixed(1) }}
                td {{ (game.shot_state.shot_state_open.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_state.shot_state_open.ppp.toFixed(1) }}
                td {{ (game.shot_state.shot_state_contested.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_state.shot_state_contested.ppp.toFixed(1) }}
                td {{ (game.shot_state.shot_state_to.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_state.shot_state_to.ppp.toFixed(1) }}
                td {{ (game.shot_state.shot_state_draw_shooting_foul.rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.shot_state.shot_state_draw_shooting_foul.ppp.toFixed(1) }}
      v-row
        v-col.mt-15(
          cols="12",
          v-for="(game, index) in teamSeasonShotStateData",
          :key="index"
        )
          .chart_title.text-center {{ getTitle(game) }}
          high-chart-combo-percent-bar(
            :barDataList="getTeamSeasonShotStateDataList(game)",
            :categories="teamSeasonShotStateCategoryList"
          )
    v-container.table_container(v-if="tab == 2")
      v-row.table_row
        v-col.table_col.table.position-relative(cols="12")
          table
            thead.top_head
              tr
                sticky-column.bdr2.bdl(
                  colspan="1",
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th.bdr(colspan="2") 失誤後快攻
                th.bdr(colspan="2") 搶籃板後快攻
                th.bdr(colspan="2") 得分後快攻
                th.bdr(colspan="2") 罰球後快攻
            thead
              tr.bottom_head
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0"
                )
                th(
                  :class="{ bdr: title.divider }",
                  v-for="(title, index) in teamSeasonTransitionFromHeadTitleList",
                  :key="index"
                ) {{ title.title }}

            tbody
              tr(
                v-for="(game, index) in teamSeasonTransitionFromData",
                :key="index"
              )
                sticky-column.bdr2.bdl(
                  style="width: 100px; min-width: 100px",
                  :offset="0",
                  :title="getTitle(game)"
                )
                td {{ game.transition_from.from_to.avg_play.toFixed(1) }}
                td.bdr {{ game.transition_from.from_to.avg_pts.toFixed(1) }}
                td {{ game.transition_from.from_reb.avg_play.toFixed(1) }}
                td.bdr {{ game.transition_from.from_reb.avg_pts.toFixed(1) }}
                td {{ game.transition_from.from_fgm.avg_play.toFixed(1) }}
                td.bdr {{ game.transition_from.from_fgm.avg_pts.toFixed(1) }}
                td {{ game.transition_from.from_ftm.avg_play.toFixed(1) }}
                td.bdr {{ game.transition_from.from_ftm.avg_pts.toFixed(1) }}
      v-row
        v-col.mt-15(
          cols="12",
          v-for="(game, index) in teamSeasonTransitionFromData",
          :key="index"
        )
          .chart_title.text-center {{ getTitle(game) }}
          high-chart-bar(
            :barDataList="getTeamSeasonTransitionFromDataList(game)",
            :categories="teamSeasonTransitionFromCategoryList"
          )
    v-container.table_container(v-if="tab == 3")
      v-row.table_row
        v-col.table_col.table.position-relative(cols="12")
          table
            thead.top_head
              tr
                sticky-column.bdr2.bdl(
                  colspan="1",
                  style="width: 200px; min-width: 200px",
                  :offset="0"
                )
                th.bdr(colspan="2") 
                th.bdr(colspan="3") 團隊進攻效率（100回合）
                th.bdr(colspan="3") 團隊進攻效率（100回合）
            thead
              tr.bottom_head
                sticky-column.bdr2.bdl(
                  style="width: 200px; min-width: 200px",
                  :offset="0",
                  :title="firstRosterCompareHeadTitleList[0].title"
                )
                th(
                  v-if="index > 0",
                  :class="{ bdr: title.divider }",
                  v-for="(title, index) in firstRosterCompareHeadTitleList",
                  :key="index"
                ) {{ title.title }}

            tbody
              tr(v-for="(game, index) in firstRosterCompareData", :key="index")
                sticky-column.bdr2.bdl(
                  style="width: 200px; min-width: 200px",
                  :offset="0",
                  :title="getRosterName(game.info.season_player_names)"
                )

                td {{ getMinutes(game.box.avg_min) }}
                td.bdr {{ game.box.plus_minus_per_36.toFixed(1) }}

                td {{ game.on_court.ppp_rounds_100.toFixed(1) }}
                td {{ game.off_court.ppp_rounds_100.toFixed(1) }}
                td.bdr {{ game.eff.ppp_rounds_100.toFixed(1) }}

                td {{ game.opp_on_court.ppp_rounds_100.toFixed(1) }}
                td {{ game.opp_off_court.ppp_rounds_100.toFixed(1) }}
                td.bdr {{ game.eff.opp_ppp_rounds_100.toFixed(1) }}
      v-row.table_row
        v-col.table_col.table.position-relative(cols="12")
          table
            thead
              tr.bottom_head
                sticky-column.bdr2.bdl(
                  style="width: 200px; min-width: 200px",
                  :offset="0",
                  :title="secondRosterCompareHeadTitleList[0].title"
                )
                th(
                  v-if="index > 0",
                  :class="{ bdr: title.divider }",
                  v-for="(title, index) in secondRosterCompareHeadTitleList",
                  :key="index"
                ) {{ title.title }}

            tbody
              tr(
                v-for="(game, index) in secondRosterCompareData",
                :key="index"
              )
                sticky-column.bdr2.bdl(
                  style="width: 200px; min-width: 200px",
                  :offset="0",
                  :title="getRosterName(game.info.season_player_names)"
                )

                td.bdr {{ (game.advancement.to_rate * 100).toFixed(1) + '%' }}
                td.bdr {{ (game.advancement.off_reb_rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.vs_defense.vs_man.ppp.toFixed(1) }}
                td.bdr {{ game.vs_defense.vs_zone.ppp.toFixed(1) }}
                td.bdr {{ game.play_type.transition.ppp.toFixed(1) }}
                td.bdr {{ game.play_type.second_chance.ppp.toFixed(1) }}
                td.bdr {{ game.play_type.transition.pts_per_36.toFixed(1) }}
                td {{ game.play_type.second_chance.pts_per_36.toFixed(1) }}
      v-row.table_row
        v-col.table_col.table.position-relative(cols="12")
          table
            thead
              tr.bottom_head
                sticky-column.bdr2.bdl(
                  style="width: 200px; min-width: 200px",
                  :offset="0",
                  :title="thirdRosterCompareHeadTitleList[0].title"
                )
                th(
                  v-if="index > 0",
                  :class="{ bdr: title.divider }",
                  v-for="(title, index) in thirdRosterCompareHeadTitleList",
                  :key="index"
                ) {{ title.title }}

            tbody
              tr(v-for="(game, index) in thirdRosterCompareData", :key="index")
                sticky-column.bdr2.bdl(
                  style="width: 200px; min-width: 200px",
                  :offset="0",
                  :title="getRosterName(game.info.season_player_names)"
                )

                td.bdr {{ (game.advancement.opp_to_rate * 100).toFixed(1) + '%' }}
                td.bdr {{ (game.advancement.def_reb_rate * 100).toFixed(1) + '%' }}
                td.bdr {{ game.opp_vs_defense.opp_vs_man.ppp.toFixed(1) }}
                td.bdr {{ game.opp_vs_defense.opp_vs_zone.ppp.toFixed(1) }}
                td.bdr {{ game.opp_play_type.opp_transition.ppp.toFixed(1) }}
                td.bdr {{ game.opp_play_type.opp_second_chance.ppp.toFixed(1) }}
                td.bdr {{ game.opp_play_type.opp_transition.pts_per_36.toFixed(1) }}
                td {{ game.opp_play_type.opp_second_chance.pts_per_36.toFixed(1) }}
</template>

<script>
import PlayersRankingCard from "@/components/league/PlayersRankingCard";
import RankingMark from "@/components/common/RankingMark";
import PercentageBar from "@/components/common/PercentageBar";
import StickyColumn from "@/components/common/Table/StickyColumn";
import HighChartAdvancedPolar from "@/components/common/HighChartAdvancedPolar";
import HighChartComboPercentBar from "@/components/common/HighChartComboPercentBar";
import HighChartBar from "@/components/common/HighChartBar";
import { getLeagueList } from "@/api/league";
import { getPlayerSeasonData } from "@/api/player";
import { getTeamRankingData, getTeamSeasonData } from "@/api/team";
import { getRosterSeasonData } from "@/api/roster";

export default {
  name: "LeagueTeamAdvancedStatistics",
  components: {
    PlayersRankingCard,
    RankingMark,
    StickyColumn,
    PercentageBar,
    HighChartAdvancedPolar,
    HighChartComboPercentBar,
    HighChartBar,
  },
  data() {
    return {
      league: this.$route.params.league,
      leagueData: {
        info: {},
      },
      teamID: this.$route.params.id,
      seasonInquiryType: 0,

      playerSeasonDataList: [],
      playersRankingLists: [],

      polarObject: {
        averageList: [],
        personalList: [],
        averagePercentList: [],
        personalPercentList: [],
      },

      bestTeamRankList: [],
      teamRankingList: [],
      barRankingList: [],

      teamHeadTitleList: [
        {
          title: "正負值",
          divider: true,
        },
        {
          title: "球權數",
          divider: false,
        },
        {
          title: "失誤率",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: true,
        },

        {
          title: "進攻籃板率",
          divider: true,
        },
        {
          title: "得分",
          divider: false,
        },
        {
          title: "次數",
          divider: false,
        },
        {
          title: "效率",
          divider: true,
        },
        {
          title: "得分",
          divider: false,
        },
        {
          title: "次數",
          divider: false,
        },
        {
          title: "效率",
          divider: true,
        },
        {
          title: "得分",
          divider: false,
        },
        {
          title: "次數",
          divider: false,
        },
        {
          title: "效率",
          divider: true,
        },
        {
          title: "對手球權數",
          divider: false,
        },
        {
          title: "對手進攻效率",
          divider: false,
        },
        {
          title: "對手失誤率",
          divider: false,
        },
        {
          title: "防守籃板率",
          divider: true,
        },
        {
          title: "得分",
          divider: false,
        },
        {
          title: "次數",
          divider: false,
        },
        {
          title: "效率",
          divider: true,
        },
        {
          title: "得分",
          divider: false,
        },
        {
          title: "次數",
          divider: false,
        },
        {
          title: "效率",
          divider: true,
        },
        {
          title: "得分",
          divider: false,
        },
        {
          title: "次數",
          divider: false,
        },
        {
          title: "效率",
          divider: true,
        },
      ],

      teamSeasonAllData: [],
      teamSeasonDateData: [],
      teamSeasonQuarterData: [],
      teamSeasonQuarterCount: 0,
      teamSeasonWinLoseData: [],
      teamSeasonVSTeamData: [],

      tab: 0,
      tabList: ["進攻類型", "出手比例", "快攻來源", "陣容比較"],

      typeInquiryType: null,
      typeInquiryList: ["整季", "依賽別", "依節次", "依勝負", "依對手"],
      dependInquiryType: null,
      dependInquiryList: [],

      teamSeasonPlayerPlayTypeData: [],
      teamSeasonPlayerPlayTypeHeadTitleList: [
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻",
          divider: false,
        },
        {
          title: "失誤",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻",
          divider: false,
        },
        {
          title: "失誤",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻",
          divider: false,
        },
        {
          title: "失誤",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻",
          divider: false,
        },
        {
          title: "失誤",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻",
          divider: false,
        },
        {
          title: "失誤",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻",
          divider: false,
        },
        {
          title: "失誤",
          divider: false,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻",
          divider: false,
        },
        {
          title: "失誤",
          divider: false,
        },
      ],
      teamSeasonPlayerPlayTypeCategoryList: [
        "快攻",
        "定點",
        "二波",
        "無球",
        "單打",
        "切傳",
        "擋拆",
      ],

      teamSeasonShotStateData: [],
      teamSeasonShotStateHeadTitleList: [
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: true,
        },
        {
          title: "比例",
          divider: false,
        },
        {
          title: "進攻效率",
          divider: false,
        },
      ],
      teamSeasonShotStateCategoryList: [
        "禁區出手",
        "外線空檔",
        "外線干擾",
        "失誤",
        "上罰球線",
      ],

      teamSeasonTransitionFromData: [],
      teamSeasonTransitionFromHeadTitleList: [
        {
          title: "次數",
          divider: false,
        },
        {
          title: "得分",
          divider: true,
        },
        {
          title: "次數",
          divider: false,
        },
        {
          title: "得分",
          divider: true,
        },
        {
          title: "次數",
          divider: false,
        },
        {
          title: "得分",
          divider: false,
        },
        {
          title: "次數",
          divider: false,
        },
        {
          title: "得分",
          divider: false,
        },
      ],
      teamSeasonTransitionFromCategoryList: [
        "失誤後快攻",
        "搶籃板後快攻",
        "得分後快攻",
        "罰球後快攻",
      ],

      tempFirstRosterCompareData: {},
      firstRosterCompareData: [],
      firstRosterCompareHeadTitleList: [
        {
          title: "陣容",
          divider: true,
        },
        {
          title: "時間",
          divider: false,
        },
        {
          title: "正負值（36min）",
          divider: true,
        },
        {
          title: "場上",
          divider: false,
        },
        {
          title: "場下",
          divider: false,
        },
        {
          title: "影響",
          divider: true,
        },
        {
          title: "場上",
          divider: false,
        },
        {
          title: "場下",
          divider: false,
        },
        {
          title: "影響",
          divider: true,
        },
      ],

      tempSecondRosterCompareData: {},
      secondRosterCompareData: [],
      secondRosterCompareHeadTitleList: [
        {
          title: "陣容",
          divider: true,
        },
        {
          title: "失誤率",
          divider: true,
        },
        {
          title: "進攻籃板率",
          divider: true,
        },
        {
          title: "盯人進攻效率",
          divider: true,
        },
        {
          title: "區域進攻效率",
          divider: true,
        },
        {
          title: "快攻效率",
          divider: true,
        },
        {
          title: "二波進攻效率",
          divider: true,
        },
        {
          title: "快攻得分per36",
          divider: true,
        },
        {
          title: "二波得分per36",
          divider: false,
        },
      ],

      tempThirdRosterCompareData: {},
      thirdRosterCompareData: [],
      thirdRosterCompareHeadTitleList: [
        {
          title: "陣容",
          divider: true,
        },
        {
          title: "對手失誤率",
          divider: true,
        },
        {
          title: "防守籃板率",
          divider: true,
        },
        {
          title: "對手盯人進攻效率",
          divider: true,
        },
        {
          title: "對手區域進攻效率",
          divider: true,
        },
        {
          title: "對手快攻效率",
          divider: true,
        },
        {
          title: "對手二波進攻效率",
          divider: true,
        },
        {
          title: "對手快攻得分per36",
          divider: true,
        },
        {
          title: "對手二波得分per36",
          divider: false,
        },
      ],
    };
  },
  watch: {
    seasonInquiryType() {
      this.playerSeasonDataList = [];
      this.playersRankingLists = [];
      this.polarObject = {
        averageList: [],
        personalList: [],
        averagePercentList: [],
        personalPercentList: [],
      };
      this.bestTeamRankList = [];
      this.teamRankingList = [];
      this.barRankingList = [];
      this.teamSeasonAllData = [];
      this.teamSeasonDateData = [];
      this.teamSeasonQuarterData = [];
      this.teamSeasonQuarterCount = 0;
      this.teamSeasonWinLoseData = [];
      this.teamSeasonVSTeamData = [];
      this.tab = 0;
      this.typeInquiryType = null;
      this.dependInquiryType = null;
      this.dependInquiryList = [];
      this.teamSeasonPlayerPlayTypeData = [];
      this.teamSeasonShotStateData = [];
      this.teamSeasonTransitionFromData = [];
      this.tempFirstRosterCompareData = {};
      this.firstRosterCompareData = [];
      this.tempSecondRosterCompareData = {};
      this.secondRosterCompareData = [];
      this.tempThirdRosterCompareData = {};
      this.thirdRosterCompareData = [];

      this.getTeamRankingData();
      this.getPlayerSeasonData();
      this.getTeamSeasonAllData();
      this.getTeamSeasonDateData();
      this.getTeamSeasonQuarterData();
      this.getTeamSeasonWinLoseData();
      this.getTeamSeasonVSTeamData();
      this.$nextTick(() => {
        this.typeInquiryType = 0;
      });
    },
    tab() {
      this.typeInquiryType = 0;
      this.teamSeasonPlayerPlayTypeData = [];
      this.teamSeasonShotStateData = [];
      this.teamSeasonTransitionFromData = [];
      this.firstRosterCompareData = [];
      this.secondRosterCompareData = [];
      this.thirdRosterCompareData = [];
      this.dependInquiryList = [];
      this.dependInquiryType = null;
      switch (this.tab) {
        case 0:
          this.getTeamSeasonPlayerPlayTypeData("NONE");
          break;
        case 1:
          this.getTeamSeasonShotStateData("NONE");
          break;
        case 2:
          this.getTeamSeasonTransitionFromData("NONE");
          break;
        case 3:
          this.getFirstRosterCompareData("NONE");
          this.getSecondRosterCompareData("NONE");
          this.getThirdRosterCompareData("NONE");
          break;
      }
    },
    typeInquiryType() {
      this.teamSeasonPlayerPlayTypeData = [];
      this.teamSeasonShotStateData = [];
      this.teamSeasonTransitionFromData = [];

      this.firstRosterCompareData = [];
      this.secondRosterCompareData = [];
      this.thirdRosterCompareData = [];
      this.dependInquiryList = [];
      this.dependInquiryType = null;
      if (this.tab != null && this.typeInquiryType != null) {
        if (this.tab == 0) {
          switch (this.typeInquiryType) {
            case 0:
              this.getTeamSeasonPlayerPlayTypeData("NONE");
              break;
            case 1:
              this.getTeamSeasonPlayerPlayTypeData("DATE");
              break;
            case 2:
              this.getTeamSeasonPlayerPlayTypeData("QUARTER");
              break;
            case 3:
              this.getTeamSeasonPlayerPlayTypeData("WIN_LOSE");
              break;
            case 4:
              this.getTeamSeasonPlayerPlayTypeData("VS_TEAM");
              break;
          }
        } else if (this.tab == 1) {
          switch (this.typeInquiryType) {
            case 0:
              this.getTeamSeasonShotStateData("NONE");
              break;
            case 1:
              this.getTeamSeasonShotStateData("DATE");
              break;
            case 2:
              this.getTeamSeasonShotStateData("QUARTER");
              break;
            case 3:
              this.getTeamSeasonShotStateData("WIN_LOSE");
              break;
            case 4:
              this.getTeamSeasonShotStateData("VS_TEAM");
              break;
          }
        } else if (this.tab == 2) {
          switch (this.typeInquiryType) {
            case 0:
              this.getTeamSeasonTransitionFromData("NONE");
              break;
            case 1:
              this.getTeamSeasonTransitionFromData("DATE");
              break;
            case 2:
              this.getTeamSeasonTransitionFromData("QUARTER");
              break;
            case 3:
              this.getTeamSeasonTransitionFromData("WIN_LOSE");
              break;
            case 4:
              this.getTeamSeasonTransitionFromData("VS_TEAM");
              break;
          }
        } else if (this.tab == 3) {
          switch (this.typeInquiryType) {
            case 0:
              this.getFirstRosterCompareData("NONE");
              break;
            case 1:
              this.getFirstRosterCompareData("DATE");
              break;
            case 2:
              this.getFirstRosterCompareData("QUARTER");
              break;
            case 3:
              this.getFirstRosterCompareData("WIN_LOSE");
              break;
            case 4:
              this.getFirstRosterCompareData("VS_TEAM");
              break;
          }
        }
      }
    },
    dependInquiryType() {
      this.firstRosterCompareData = [];
      this.secondRosterCompareData = [];
      this.thirdRosterCompareData = [];
      if (this.dependInquiryType != null) {
        this.firstRosterCompareData = this.tempFirstRosterCompareData[
          this.dependInquiryList[this.dependInquiryType]
        ].sort(function (a, b) {
          return b.box.avg_min - a.box.avg_min;
        });
        this.secondRosterCompareData = this.tempSecondRosterCompareData[
          this.dependInquiryList[this.dependInquiryType]
        ].sort(function (a, b) {
          return b.box.avg_min - a.box.avg_min;
        });
        this.thirdRosterCompareData = this.tempThirdRosterCompareData[
          this.dependInquiryList[this.dependInquiryType]
        ].sort(function (a, b) {
          return b.box.avg_min - a.box.avg_min;
        });
      }
    },
  },
  created() {
    this.getLeagueList();
  },
  methods: {
    getLeagueList() {
      getLeagueList().then((response) => {
        for (let index in response.data) {
          let temp = response.data[index];
          if (temp.name.indexOf(this.league) != -1) {
            this.leagueData = Object.assign({}, temp);
          }
        }

        this.getTeamRankingData();
        this.getPlayerSeasonData();
        this.getTeamSeasonAllData();
        this.getTeamSeasonDateData();
        this.getTeamSeasonQuarterData();
        this.getTeamSeasonWinLoseData();
        this.getTeamSeasonVSTeamData();

        this.typeInquiryType = 0;
      });
    },
    getPlayerSeasonData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info,ranking",
        team_id: this.teamID,
      };
      getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonDataList = response.data;
        this.getPlayerRankingList();
      });
    },
    getTeamRankingData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, ranking",
        ranking: 1,
      };
      getTeamRankingData(temp).then((response) => {
        this.bestTeamRankList = response.data;
        this.getTeamSeasonData();
      });
    },
    getTeamSeasonData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info,ranking",
        team_id: this.teamID,
      };
      getTeamSeasonData(temp).then((response) => {
        this.teamRankingList = response.data;
        this.getBarRankingList();
        this.getPolarObject();
      });
    },
    getTeamSeasonAllData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, box, advancement, vs_defense, opp_vs_defense",
        team_id: this.teamID,
        split_type: "NONE",
      };
      getTeamSeasonData(temp).then((response) => {
        this.teamSeasonAllData = response.data;
      });
    },
    getTeamSeasonDateData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, box, advancement, vs_defense, opp_vs_defense",
        team_id: this.teamID,
        split_type: "DATE",
      };
      getTeamSeasonData(temp).then((response) => {
        this.teamSeasonDateData = response.data;
      });
    },
    getTeamSeasonQuarterData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, box, advancement, vs_defense, opp_vs_defense",
        team_id: this.teamID,
        show_all_quarters: true,
        split_type: "NONE",
      };
      getTeamSeasonData(temp).then((response) => {
        this.teamSeasonQuarterData = response.data;
        this.teamSeasonQuarterCount = this.teamSeasonQuarterData[0].box.length;
      });
    },
    getTeamSeasonWinLoseData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, box, advancement, vs_defense, opp_vs_defense",
        team_id: this.teamID,
        split_type: "WIN_LOSE",
      };
      getTeamSeasonData(temp).then((response) => {
        this.teamSeasonWinLoseData = response.data;
      });
    },
    getTeamSeasonVSTeamData() {
      let temp = {
        season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
        part: "info, box, advancement, vs_defense, opp_vs_defense",
        team_id: this.teamID,
        split_type: "VS_TEAM",
      };
      getTeamSeasonData(temp).then((response) => {
        this.teamSeasonVSTeamData = response.data;
      });
    },
    getTeamSeasonPlayerPlayTypeData(split) {
      if ("QUARTER" == split) {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, player_play_type",
          team_id: this.teamID,
          split_type: "NONE",
          show_all_quarters: true,
        };
        getTeamSeasonData(temp).then((response) => {
          let tempList = response.data;
          let count = tempList[0].player_play_type.dnk.length;
          for (let i = 1; i < count; i++) {
            let temp = {
              quarter: i,
              info: tempList[0].info,
              player_play_type: {
                dnk: tempList[0].player_play_type.dnk[i],
                iso: tempList[0].player_play_type.iso[i],
                off_ball: tempList[0].player_play_type.off_ball[i],
                pnr: tempList[0].player_play_type.pnr[i],
                second_chance: tempList[0].player_play_type.second_chance[i],
                spot_up: tempList[0].player_play_type.spot_up[i],
                transition: tempList[0].player_play_type.transition[i],
              },
            };
            this.teamSeasonPlayerPlayTypeData.push(temp);
          }
        });
      } else {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, player_play_type",
          team_id: this.teamID,
          split_type: split,
        };
        getTeamSeasonData(temp).then((response) => {
          this.teamSeasonPlayerPlayTypeData = response.data;
        });
      }
    },
    getTeamSeasonShotStateData(split) {
      if ("QUARTER" == split) {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, shot_state",
          team_id: this.teamID,
          split_type: "NONE",
          show_all_quarters: true,
        };
        getTeamSeasonData(temp).then((response) => {
          let tempList = response.data;
          let count = tempList[0].shot_state.shot_state_to.length;
          for (let i = 1; i < count; i++) {
            let temp = {
              quarter: i,
              info: tempList[0].info,
              shot_state: {
                shot_state_to: tempList[0].shot_state.shot_state_to[i],
                shot_state_contested:
                  tempList[0].shot_state.shot_state_contested[i],
                shot_state_draw_shooting_foul:
                  tempList[0].shot_state.shot_state_draw_shooting_foul[i],
                shot_state_open: tempList[0].shot_state.shot_state_open[i],
                shot_state_paint: tempList[0].shot_state.shot_state_paint[i],
              },
            };
            this.teamSeasonShotStateData.push(temp);
          }
        });
      } else {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, shot_state",
          team_id: this.teamID,
          split_type: split,
        };
        getTeamSeasonData(temp).then((response) => {
          this.teamSeasonShotStateData = response.data;
        });
      }
    },
    getTeamSeasonTransitionFromData(split) {
      if ("QUARTER" == split) {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, transition_from",
          team_id: this.teamID,
          split_type: "NONE",
          show_all_quarters: true,
        };
        getTeamSeasonData(temp).then((response) => {
          let tempList = response.data;
          let count = tempList[0].transition_from.from_fgm.length;
          for (let i = 1; i < count; i++) {
            let temp = {
              quarter: i,
              info: tempList[0].info,
              transition_from: {
                from_fgm: tempList[0].transition_from.from_fgm[i],
                from_ftm: tempList[0].transition_from.from_ftm[i],
                from_reb: tempList[0].transition_from.from_reb[i],
                from_to: tempList[0].transition_from.from_to[i],
              },
            };
            this.teamSeasonTransitionFromData.push(temp);
          }
        });
      } else {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, transition_from",
          team_id: this.teamID,
          split_type: split,
        };
        getTeamSeasonData(temp).then((response) => {
          this.teamSeasonTransitionFromData = response.data;
        });
      }
    },
    getFirstRosterCompareData(split) {
      if ("QUARTER" == split) {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, box, on_court, off_court, opp_on_court, opp_off_court, eff",
          team_id: this.teamID,
          split_type: "NONE",
          show_all_quarters: true,
          count: 6,
        };
        getRosterSeasonData(temp).then((response) => {
          let tempList = response.data;
          let count = tempList[0].box.length;
          let finalList = [];
          for (let i = 1; i < count; i++) {
            let dataList = tempList
              .sort(function (a, b) {
                return b.box[i].avg_min - a.box[i].avg_min;
              })
              .slice(0, 6);
            for (let index in dataList) {
              let temp = dataList[index];
              let tempData = {
                quarter: this.getQuarterText(i),
                info: temp.info,
                box: temp.box[i],
                eff: temp.eff[i],
                off_court: temp.off_court[i],
                on_court: temp.on_court[i],
                opp_off_court: temp.opp_off_court[i],
                opp_on_court: temp.opp_on_court[i],
              };
              finalList.push(tempData);
            }
          }
          this.getCurrentRosterCompareData(1, finalList);
          this.getSecondRosterCompareData(split);
        });
      } else {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, box, on_court, off_court, opp_on_court, opp_off_court, eff",
          team_id: this.teamID,
          split_type: split,
          count: 6,
        };
        getRosterSeasonData(temp).then((response) => {
          this.getCurrentRosterCompareData(1, response.data);
          this.getSecondRosterCompareData(split);
        });
      }
    },
    getSecondRosterCompareData(split) {
      if ("QUARTER" == split) {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, box, advancement, vs_defense, play_type",
          team_id: this.teamID,
          split_type: "NONE",
          show_all_quarters: true,
          count: 6,
        };
        getRosterSeasonData(temp).then((response) => {
          let tempList = response.data;
          let count = tempList[0].box.length;
          let finalList = [];
          for (let i = 1; i < count; i++) {
            let dataList = tempList
              .sort(function (a, b) {
                return b.box[i].avg_min - a.box[i].avg_min;
              })
              .slice(0, 6);
            for (let index in dataList) {
              let temp = dataList[index];
              let tempData = {
                quarter: this.getQuarterText(i),
                info: temp.info,
                box: temp.box[i],
                advancement: temp.advancement[i],
                vs_defense: {
                  vs_full_court: temp.vs_defense.vs_full_court[i],
                  vs_man: temp.vs_defense.vs_man[i],
                  vs_zone: temp.vs_defense.vs_zone[i],
                },
                play_type: {
                  second_chance: temp.play_type.second_chance[i],
                  transition: temp.play_type.transition[i],
                },
              };
              finalList.push(tempData);
            }
          }
          this.getCurrentRosterCompareData(2, finalList);
          this.getThirdRosterCompareData(split);
        });
      } else {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, box, advancement, vs_defense, play_type",
          team_id: this.teamID,
          split_type: split,
          count: 6,
        };
        getRosterSeasonData(temp).then((response) => {
          this.getCurrentRosterCompareData(2, response.data);
          this.getThirdRosterCompareData(split);
        });
      }
    },
    getThirdRosterCompareData(split) {
      if ("QUARTER" == split) {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, box, advancement, opp_vs_defense, opp_play_type",
          team_id: this.teamID,
          split_type: "NONE",
          show_all_quarters: true,
          count: 6,
        };
        getRosterSeasonData(temp).then((response) => {
          let tempList = response.data;
          let count = tempList[0].box.length;
          let finalList = [];
          for (let i = 1; i < count; i++) {
            let dataList = tempList
              .sort(function (a, b) {
                return b.box[i].avg_min - a.box[i].avg_min;
              })
              .slice(0, 6);
            for (let index in dataList) {
              let temp = dataList[index];
              let tempData = {
                quarter: this.getQuarterText(i),
                info: temp.info,
                box: temp.box[i],
                advancement: temp.advancement[i],
                opp_vs_defense: {
                  opp_vs_full_court: temp.opp_vs_defense.opp_vs_full_court[i],
                  opp_vs_man: temp.opp_vs_defense.opp_vs_man[i],
                  opp_vs_zone: temp.opp_vs_defense.opp_vs_zone[i],
                },
                opp_play_type: {
                  opp_second_chance: temp.opp_play_type.opp_second_chance[i],
                  opp_transition: temp.opp_play_type.opp_transition[i],
                },
              };
              finalList.push(tempData);
            }
          }
          this.getCurrentRosterCompareData(3, finalList);
        });
      } else {
        let temp = {
          season_id: this.leagueData.info.seasons[this.seasonInquiryType].id,
          part: "info, box, advancement, opp_vs_defense, opp_play_type",
          team_id: this.teamID,
          split_type: split,
          count: 6,
        };
        getRosterSeasonData(temp).then((response) => {
          this.getCurrentRosterCompareData(3, response.data);
        });
      }
    },
    getPolarObject() {
      this.polarObject.personalList.push(
        Number(this.teamRankingList[0].ranking.ppp.value.toFixed(1))
      );
      this.polarObject.personalList.push(
        Number(
          (this.teamRankingList[0].ranking.off_reb_rate.value * 100).toFixed(1)
        )
      );
      this.polarObject.personalList.push(
        Number(
          (this.teamRankingList[0].ranking.opp_to_rate.value * 100).toFixed(1)
        )
      );
      this.polarObject.personalList.push(
        Number(this.teamRankingList[0].ranking.opp_ppp.value.toFixed(1))
      );
      this.polarObject.personalList.push(
        Number(
          (this.teamRankingList[0].ranking.def_reb_rate.value * 100).toFixed(1)
        )
      );
      this.polarObject.personalList.push(
        Number((this.teamRankingList[0].ranking.to_rate.value * 100).toFixed(1))
      );

      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.teamRankingList[0].ranking.ppp.value /
              this.bestTeamRankList.ppp[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.teamRankingList[0].ranking.off_reb_rate.value /
              this.bestTeamRankList.off_reb_rate[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.teamRankingList[0].ranking.opp_to_rate.value /
              this.bestTeamRankList.opp_to_rate[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.teamRankingList[0].ranking.opp_ppp.value /
              this.bestTeamRankList.opp_ppp[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.teamRankingList[0].ranking.def_reb_rate.value /
              this.bestTeamRankList.def_reb_rate[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.personalPercentList.push(
        Number(
          (
            (this.teamRankingList[0].ranking.to_rate.value /
              this.bestTeamRankList.to_rate[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );

      this.polarObject.averageList.push(
        Number(this.teamRankingList[0].ranking.ppp.avg.toFixed(1))
      );
      this.polarObject.averageList.push(
        Number(
          (this.teamRankingList[0].ranking.off_reb_rate.avg * 100).toFixed(1)
        )
      );
      this.polarObject.averageList.push(
        Number(
          (this.teamRankingList[0].ranking.opp_to_rate.avg * 100).toFixed(1)
        )
      );
      this.polarObject.averageList.push(
        Number(this.teamRankingList[0].ranking.opp_ppp.avg.toFixed(1))
      );
      this.polarObject.averageList.push(
        Number(
          (this.teamRankingList[0].ranking.def_reb_rate.avg * 100).toFixed(1)
        )
      );
      this.polarObject.averageList.push(
        Number((this.teamRankingList[0].ranking.to_rate.avg * 100).toFixed(1))
      );

      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.teamRankingList[0].ranking.ppp.avg /
              this.bestTeamRankList.ppp[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.teamRankingList[0].ranking.off_reb_rate.avg /
              this.bestTeamRankList.off_reb_rate[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.teamRankingList[0].ranking.opp_to_rate.avg /
              this.bestTeamRankList.opp_to_rate[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.teamRankingList[0].ranking.opp_ppp.avg /
              this.bestTeamRankList.opp_ppp[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.teamRankingList[0].ranking.def_reb_rate.avg /
              this.bestTeamRankList.def_reb_rate[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
      this.polarObject.averagePercentList.push(
        Number(
          (
            (this.teamRankingList[0].ranking.to_rate.avg /
              this.bestTeamRankList.to_rate[0].ranking.value) *
            100
          ).toFixed(1)
        )
      );
    },
    getTitle(game) {
      switch (this.typeInquiryType) {
        case 0:
          return "整季";
        case 1:
          return game.info.split_info.name;
        case 2:
          return this.getQuarterText(game.quarter);
        case 3:
          return game.info.split == "WIN" ? "勝" : "負";
        case 4:
          return "vs." + game.info.split_info.name;
      }
    },
    getQuarterText(count) {
      if (count == 1) {
        return "第一節";
      } else if (count == 2) {
        return "第二節";
      } else if (count == 3) {
        return "第三節";
      } else if (count == 4) {
        return "第四節";
      } else {
        return "OT" + (count - 4);
      }
    },
    getPlayerRankingList() {
      let pointsRanking = {
        type: "offensiveRating",
        engTitle: "Offensive Rating",
        title: "進攻效率",
        rankings: this.bindPlayerRankingData("offensiveRating"),
      };
      this.playersRankingLists.push(pointsRanking);

      let reboundRanking = {
        type: "turnover",
        engTitle: "Turnover",
        title: "失誤率",
        percent: true,
        rankings: this.bindPlayerRankingData("turnover"),
      };
      this.playersRankingLists.push(reboundRanking);

      let assistRanking = {
        type: "offensiveRebound",
        engTitle: "Offensive Rebound",
        title: "進攻籃板率",
        percent: true,
        rankings: this.bindPlayerRankingData("offensiveRebound"),
      };
      this.playersRankingLists.push(assistRanking);

      let stealRanking = {
        type: "defensiveRebound",
        engTitle: "Defensive Rebound",
        title: "防守籃板率",
        percent: true,
        rankings: this.bindPlayerRankingData("defensiveRebound"),
      };
      this.playersRankingLists.push(stealRanking);
    },
    bindPlayerRankingData(type) {
      let tempList;
      let rankingList;
      switch (type) {
        case "offensiveRating":
          tempList = this.playerSeasonDataList
            .sort(function (a, b) {
              return a.ranking.ppp.ranking - b.ranking.ppp.ranking;
            })
            .slice(0, 3);
          rankingList = [];
          for (let index in tempList) {
            let item = tempList[index];
            let rankingdata = {
              info: item.info,
              ranking: item.ranking.ppp,
            };
            rankingList.push(rankingdata);
          }
          return rankingList;
        case "turnover":
          tempList = this.playerSeasonDataList
            .sort(function (a, b) {
              return a.ranking.to_rate.ranking - b.ranking.to_rate.ranking;
            })
            .slice(0, 3);
          rankingList = [];
          for (let index in tempList) {
            let item = tempList[index];
            let rankingdata = {
              info: item.info,
              ranking: item.ranking.to_rate,
            };
            rankingList.push(rankingdata);
          }
          return rankingList;
        case "offensiveRebound":
          tempList = this.playerSeasonDataList
            .sort(function (a, b) {
              return (
                a.ranking.off_reb_rate.ranking - b.ranking.off_reb_rate.ranking
              );
            })
            .slice(0, 3);
          rankingList = [];
          for (let index in tempList) {
            let item = tempList[index];
            let rankingdata = {
              info: item.info,
              ranking: item.ranking.off_reb_rate,
            };
            rankingList.push(rankingdata);
          }
          return rankingList;
        case "defensiveRebound":
          tempList = this.playerSeasonDataList
            .sort(function (a, b) {
              return (
                a.ranking.def_reb_rate.ranking - b.ranking.def_reb_rate.ranking
              );
            })
            .slice(0, 3);
          rankingList = [];
          for (let index in tempList) {
            let item = tempList[index];
            let rankingdata = {
              info: item.info,
              ranking: item.ranking.def_reb_rate,
            };
            rankingList.push(rankingdata);
          }
          return rankingList;
      }
    },
    getBarRankingList() {
      let offRate = {
        title: "進攻效率",
        ranking: this.teamRankingList[0].ranking.ppp.ranking,
        value: Number(this.teamRankingList[0].ranking.ppp.value.toFixed(1)),
        deno: Number(this.bestTeamRankList.ppp[0].ranking.value),
        percent: false,
      };
      this.barRankingList.push(offRate);

      let toRate = {
        title: "失誤率",
        ranking: this.teamRankingList[0].ranking.to_rate.ranking,
        value: Number(this.teamRankingList[0].ranking.to_rate.value),
        deno: Number(this.bestTeamRankList.to_rate[0].ranking.value),
        percent: true,
      };
      this.barRankingList.push(toRate);

      let offReboundRate = {
        title: "進攻籃板率",
        ranking: this.teamRankingList[0].ranking.off_reb_rate.ranking,
        value: Number(this.teamRankingList[0].ranking.off_reb_rate.value),
        deno: Number(this.bestTeamRankList.off_reb_rate[0].ranking.value),
        percent: true,
      };
      this.barRankingList.push(offReboundRate);

      let defReboundRate = {
        title: "防守籃板率",
        ranking: this.teamRankingList[0].ranking.def_reb_rate.ranking,
        value: Number(this.teamRankingList[0].ranking.def_reb_rate.value),
        deno: Number(this.bestTeamRankList.def_reb_rate[0].ranking.value),
        percent: true,
      };
      this.barRankingList.push(defReboundRate);
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
    getTeamSeasonPlayerPlayTypeDataList(game) {
      let tempTeamSeasonPlayerPlayTypeDataList = [];
      tempTeamSeasonPlayerPlayTypeDataList.push({
        name: "比例",
        data: [],
        color: "#94B7D4",
      });
      tempTeamSeasonPlayerPlayTypeDataList.push({
        name: "進攻效率",
        data: [],
        color: "#487AA4",
      });
      tempTeamSeasonPlayerPlayTypeDataList.push({
        name: "失誤率",
        data: [],
        color: "#FFBB50",
      });

      tempTeamSeasonPlayerPlayTypeDataList[0].data.push(
        game.player_play_type.transition.rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[0].data.push(
        game.player_play_type.spot_up.rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[0].data.push(
        game.player_play_type.second_chance.rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[0].data.push(
        game.player_play_type.off_ball.rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[0].data.push(
        game.player_play_type.iso.rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[0].data.push(
        game.player_play_type.dnk.rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[0].data.push(
        game.player_play_type.pnr.rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[1].data.push(
        Number(game.player_play_type.transition.ppp.toFixed(1))
      );
      tempTeamSeasonPlayerPlayTypeDataList[1].data.push(
        Number(game.player_play_type.spot_up.ppp.toFixed(1))
      );
      tempTeamSeasonPlayerPlayTypeDataList[1].data.push(
        Number(game.player_play_type.second_chance.ppp.toFixed(1))
      );
      tempTeamSeasonPlayerPlayTypeDataList[1].data.push(
        Number(game.player_play_type.off_ball.ppp.toFixed(1))
      );
      tempTeamSeasonPlayerPlayTypeDataList[1].data.push(
        Number(game.player_play_type.iso.ppp.toFixed(1))
      );
      tempTeamSeasonPlayerPlayTypeDataList[1].data.push(
        Number(game.player_play_type.dnk.ppp.toFixed(1))
      );
      tempTeamSeasonPlayerPlayTypeDataList[1].data.push(
        Number(game.player_play_type.pnr.ppp.toFixed(1))
      );
      tempTeamSeasonPlayerPlayTypeDataList[2].data.push(
        game.player_play_type.transition.to_rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[2].data.push(
        game.player_play_type.spot_up.to_rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[2].data.push(
        game.player_play_type.second_chance.to_rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[2].data.push(
        game.player_play_type.off_ball.to_rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[2].data.push(
        game.player_play_type.iso.to_rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[2].data.push(
        game.player_play_type.dnk.to_rate
      );
      tempTeamSeasonPlayerPlayTypeDataList[2].data.push(
        game.player_play_type.pnr.to_rate
      );
      return tempTeamSeasonPlayerPlayTypeDataList;
    },
    getTeamSeasonShotStateDataList(game) {
      let tempTeamSeasonShotStateDataList = [];
      tempTeamSeasonShotStateDataList.push({
        name: "比例",
        data: [],
        color: "#94B7D4",
      });
      tempTeamSeasonShotStateDataList.push({
        name: "進攻效率",
        data: [],
        color: "#487AA4",
      });
      tempTeamSeasonShotStateDataList[0].data.push(
        game.shot_state.shot_state_paint.rate
      );
      tempTeamSeasonShotStateDataList[0].data.push(
        game.shot_state.shot_state_open.rate
      );
      tempTeamSeasonShotStateDataList[0].data.push(
        game.shot_state.shot_state_contested.rate
      );
      tempTeamSeasonShotStateDataList[0].data.push(
        game.shot_state.shot_state_to.rate
      );
      tempTeamSeasonShotStateDataList[0].data.push(
        game.shot_state.shot_state_draw_shooting_foul.rate
      );
      tempTeamSeasonShotStateDataList[1].data.push(
        Number(game.shot_state.shot_state_paint.ppp.toFixed(1))
      );
      tempTeamSeasonShotStateDataList[1].data.push(
        Number(game.shot_state.shot_state_open.ppp.toFixed(1))
      );
      tempTeamSeasonShotStateDataList[1].data.push(
        Number(game.shot_state.shot_state_contested.ppp.toFixed(1))
      );
      tempTeamSeasonShotStateDataList[1].data.push(
        Number(game.shot_state.shot_state_to.ppp.toFixed(1))
      );
      tempTeamSeasonShotStateDataList[1].data.push(
        Number(game.shot_state.shot_state_draw_shooting_foul.ppp.toFixed(1))
      );
      return tempTeamSeasonShotStateDataList;
    },
    getTeamSeasonTransitionFromDataList(game) {
      let tempTeamSeasonTransitionFromDataList = [];
      tempTeamSeasonTransitionFromDataList.push({
        name: "次數",
        data: [],
        color: "#94B7D4",
      });
      tempTeamSeasonTransitionFromDataList.push({
        name: "分數",
        data: [],
        color: "#487AA4",
      });

      tempTeamSeasonTransitionFromDataList[0].data.push(
        Number(game.transition_from.from_to.avg_play.toFixed(1))
      );
      tempTeamSeasonTransitionFromDataList[0].data.push(
        Number(game.transition_from.from_reb.avg_play.toFixed(1))
      );
      tempTeamSeasonTransitionFromDataList[0].data.push(
        Number(game.transition_from.from_fgm.avg_play.toFixed(1))
      );
      tempTeamSeasonTransitionFromDataList[0].data.push(
        Number(game.transition_from.from_ftm.avg_play.toFixed(1))
      );
      tempTeamSeasonTransitionFromDataList[1].data.push(
        Number(game.transition_from.from_to.avg_pts.toFixed(1))
      );
      tempTeamSeasonTransitionFromDataList[1].data.push(
        Number(game.transition_from.from_reb.avg_pts.toFixed(1))
      );
      tempTeamSeasonTransitionFromDataList[1].data.push(
        Number(game.transition_from.from_fgm.avg_pts.toFixed(1))
      );
      tempTeamSeasonTransitionFromDataList[1].data.push(
        Number(game.transition_from.from_ftm.avg_pts.toFixed(1))
      );
      return tempTeamSeasonTransitionFromDataList;
    },
    getMinutes(temp) {
      let min = parseInt(temp / 60);
      let second = (temp % 60).toFixed(0);
      if (second < 10) {
        return min + ":0" + second;
      }
      return min + ":" + second;
    },
    getRosterName(roster) {
      let members = "";
      for (let index in roster) {
        let temp = roster[index];
        if (members == "") {
          members = members + temp;
        } else {
          members = members + " " + temp;
        }
      }
      return members;
    },
    getCurrentRosterCompareData(table, data) {
      this.dependInquiryList = [];
      if (1 == table) {
        this.tempFirstRosterCompareData = {};
        switch (this.typeInquiryType) {
          case 0:
            this.firstRosterCompareData = data;
            break;
          case 1:
            for (let index in data) {
              let temp = data[index];
              let key = temp.info.split_info.name;
              if (!this.dependInquiryList.includes(key)) {
                this.dependInquiryList.push(key);
                this.tempFirstRosterCompareData[key] = [];
                this.tempFirstRosterCompareData[key].push(temp);
              } else {
                this.tempFirstRosterCompareData[key].push(temp);
              }
            }
            break;
          case 2:
            for (let index in data) {
              let temp = data[index];
              let key = temp.quarter;
              if (!this.dependInquiryList.includes(key)) {
                this.dependInquiryList.push(key);
                this.tempFirstRosterCompareData[key] = [];
                this.tempFirstRosterCompareData[key].push(temp);
              } else {
                this.tempFirstRosterCompareData[key].push(temp);
              }
            }
            break;
          case 3:
            for (let index in data) {
              let temp = data[index];
              let key = temp.info.split == "WIN" ? "勝" : "負";
              if (!this.dependInquiryList.includes(key)) {
                this.dependInquiryList.push(key);
                this.tempFirstRosterCompareData[key] = [];
                this.tempFirstRosterCompareData[key].push(temp);
              } else {
                this.tempFirstRosterCompareData[key].push(temp);
              }
            }
            break;
          case 4:
            for (let index in data) {
              let temp = data[index];
              let key = "vs." + temp.info.split_info.name;
              if (!this.dependInquiryList.includes(key)) {
                this.dependInquiryList.push(key);
                this.tempFirstRosterCompareData[key] = [];
                this.tempFirstRosterCompareData[key].push(temp);
              } else {
                this.tempFirstRosterCompareData[key].push(temp);
              }
            }
            break;
        }
      } else if (2 == table) {
        this.tempSecondRosterCompareData = {};
        switch (this.typeInquiryType) {
          case 0:
            this.secondRosterCompareData = data;
            break;
          case 1:
            for (let index in data) {
              let temp = data[index];
              let key = temp.info.split_info.name;
              if (!this.dependInquiryList.includes(key)) {
                this.dependInquiryList.push(key);
                this.tempSecondRosterCompareData[key] = [];
                this.tempSecondRosterCompareData[key].push(temp);
              } else {
                this.tempSecondRosterCompareData[key].push(temp);
              }
            }
            break;
          case 2:
            for (let index in data) {
              let temp = data[index];
              let key = temp.quarter;
              if (!this.dependInquiryList.includes(key)) {
                this.dependInquiryList.push(key);
                this.tempSecondRosterCompareData[key] = [];
                this.tempSecondRosterCompareData[key].push(temp);
              } else {
                this.tempSecondRosterCompareData[key].push(temp);
              }
            }
            break;
          case 3:
            for (let index in data) {
              let temp = data[index];
              let key = temp.info.split == "WIN" ? "勝" : "負";
              if (!this.dependInquiryList.includes(key)) {
                this.dependInquiryList.push(key);
                this.tempSecondRosterCompareData[key] = [];
                this.tempSecondRosterCompareData[key].push(temp);
              } else {
                this.tempSecondRosterCompareData[key].push(temp);
              }
            }
            break;
          case 4:
            for (let index in data) {
              let temp = data[index];
              let key = "vs." + temp.info.split_info.name;
              if (!this.dependInquiryList.includes(key)) {
                this.dependInquiryList.push(key);
                this.tempSecondRosterCompareData[key] = [];
                this.tempSecondRosterCompareData[key].push(temp);
              } else {
                this.tempSecondRosterCompareData[key].push(temp);
              }
            }
            break;
        }
      } else if (3 == table) {
        this.tempThirdRosterCompareData = {};
        switch (this.typeInquiryType) {
          case 0:
            this.thirdRosterCompareData = data;
            break;
          case 1:
            for (let index in data) {
              let temp = data[index];
              let key = temp.info.split_info.name;
              if (!this.dependInquiryList.includes(key)) {
                this.dependInquiryList.push(key);
                this.tempThirdRosterCompareData[key] = [];
                this.tempThirdRosterCompareData[key].push(temp);
              } else {
                this.tempThirdRosterCompareData[key].push(temp);
              }
            }
            this.dependInquiryType = 0;
            break;
          case 2:
            for (let index in data) {
              let temp = data[index];
              let key = temp.quarter;
              if (!this.dependInquiryList.includes(key)) {
                this.dependInquiryList.push(key);
                this.tempThirdRosterCompareData[key] = [];
                this.tempThirdRosterCompareData[key].push(temp);
              } else {
                this.tempThirdRosterCompareData[key].push(temp);
              }
            }
            this.dependInquiryType = 0;
            break;
          case 3:
            for (let index in data) {
              let temp = data[index];
              let key = temp.info.split == "WIN" ? "勝" : "負";
              if (!this.dependInquiryList.includes(key)) {
                this.dependInquiryList.push(key);
                this.tempThirdRosterCompareData[key] = [];
                this.tempThirdRosterCompareData[key].push(temp);
              } else {
                this.tempThirdRosterCompareData[key].push(temp);
              }
            }
            this.dependInquiryType = 0;
            break;
          case 4:
            for (let index in data) {
              let temp = data[index];
              let key = "vs." + temp.info.split_info.name;
              if (!this.dependInquiryList.includes(key)) {
                this.dependInquiryList.push(key);
                this.tempThirdRosterCompareData[key] = [];
                this.tempThirdRosterCompareData[key].push(temp);
              } else {
                this.tempThirdRosterCompareData[key].push(temp);
              }
            }
            this.dependInquiryType = 0;
            break;
        }
      }
    },
    playerClick(player) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          id: player.info.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";

.bottom_head th {
  white-space: initial;
  min-width: 50px;
}

.img_circle {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
}

.ranking_list {
  li {
    height: 42px;
    border-bottom: #d2d2d2 1px solid;

    &:first-child {
      color: white;
      background-color: #002442;
      border-bottom: none;

      .number {
        text-align: right;
        flex-grow: 7;
      }

      .player {
        flex-grow: 5;
        margin-left: 10px;
      }

      .score {
        font-family: Helvetica;
        font-size: 24px;
        font-style: italic;
        font-weight: bold;
      }
    }

    &:last-child {
      border-bottom: none;
    }

    .ranking {
      flex-grow: 0;
    }

    .number {
      flex-grow: 3;
      text-align: center;
    }

    .player {
      flex-grow: 5;
    }

    .score {
      flex-grow: 4;
    }
  }
}

.ranking_league_bar {
  .title {
    flex-shrink: 0;
    min-width: 100px;
  }

  .ranking_mark {
    margin-right: 16px;
    flex-shrink: 0;
  }
}

input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  background: url("~@/assets/img/search.png") no-repeat right center;
  background-position: calc(100% - 10px) center;
}

.tab_container {
  position: relative;
  margin-top: 42px;
  padding: 0;

  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    bottom: 0;
    height: 3px;
    left: 0;
    background: $paleGray;
  }
}

table {
  text-align: center;
}

.table_row {
  padding: 15px;
}
.table_col {
  padding: 0;
}
</style>
